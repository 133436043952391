var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Alignments", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "Change the alignment of the buttons with the property alignments. Allowed values are:"
        ),
      ]),
      _c("vx-list", {
        staticClass: "mt-3",
        attrs: { list: ["center", "right", "fixed"] },
      }),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("h6", { staticClass: "mb-3" }, [_vm._v(" Default ")]),
          _c(
            "vs-tabs",
            [
              _c("vs-tab", { attrs: { label: "Home" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Documents" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Contributors" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Ecosystem" } }, [_c("div")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("h6", { staticClass: "mb-3" }, [_vm._v(" Center ")]),
          _c(
            "vs-tabs",
            { attrs: { alignment: "center" } },
            [
              _c("vs-tab", { attrs: { label: "Home" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Documents" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Contributors" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Ecosystem" } }, [_c("div")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("h6", { staticClass: "mb-3" }, [_vm._v(" Right ")]),
          _c(
            "vs-tabs",
            { attrs: { alignment: "right" } },
            [
              _c("vs-tab", { attrs: { label: "Home" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Documents" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Contributors" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Ecosystem" } }, [_c("div")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("h6", { staticClass: "mb-3" }, [_vm._v(" Fixed ")]),
          _c(
            "vs-tabs",
            { attrs: { alignment: "fixed" } },
            [
              _c("vs-tab", { attrs: { label: "Home" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Documents" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Contributors" } }, [_c("div")]),
              _c("vs-tab", { attrs: { label: "Ecosystem" } }, [_c("div")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div class="mt-5">\n    <h3> Default </h3>\n    <vs-tabs>\n      <vs-tab label="Home">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Documents">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Contributors">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Ecosystem">\n        <div></div>\n      </vs-tab>\n    </vs-tabs>\n  </div>\n\n  <div class="mt-5">\n    <h3> Center </h3>\n    <vs-tabs alignment="center">\n      <vs-tab label="Home">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Documents">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Contributors">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Ecosystem">\n        <div></div>\n      </vs-tab>\n    </vs-tabs>\n  </div>\n\n  <div class="mt-5">\n    <h3> Right </h3>\n    <vs-tabs alignment="right">\n      <vs-tab label="Home">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Documents">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Contributors">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Ecosystem">\n        <div></div>\n      </vs-tab>\n    </vs-tabs>\n  </div>\n\n  <div class="mt-5">\n    <h3> Fixed </h3>\n    <vs-tabs alignment="fixed">\n      <vs-tab label="Home">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Documents">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Contributors">\n        <div></div>\n      </vs-tab>\n      <vs-tab label="Ecosystem">\n        <div></div>\n      </vs-tab>\n    </vs-tabs>\n  </div>\n</template>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }