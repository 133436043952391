var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Icons", "code-toggler": "" } },
    [
      _c(
        "vs-tabs",
        [
          _c("vs-tab", {
            attrs: { label: "Home", "icon-pack": "feather", icon: "icon-home" },
          }),
          _c("vs-tab", {
            attrs: { label: "Box", "icon-pack": "feather", icon: "icon-box" },
          }),
          _c("vs-tab", {
            attrs: { label: "Mail", "icon-pack": "feather", icon: "icon-mail" },
          }),
          _c("vs-tab", {
            attrs: {
              label: "Heart",
              "icon-pack": "feather",
              icon: "icon-heart",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template lang="html">\n  <vs-tabs>\n    <vs-tab label="Home" icon-pack="feather" icon="icon-home">\n    </vs-tab>\n    <vs-tab label="Box" icon-pack="feather" icon="icon-box">\n    </vs-tab>\n    <vs-tab label="Mail" icon-pack="feather" icon="icon-mail">\n    </vs-tab>\n    <vs-tab label="Heart" icon-pack="feather" icon="icon-heart">\n    </vs-tab>\n  </vs-tabs>\n</template>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }